import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlash, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getDdOptionForUI } from "../../store/reducer/ddOption/ddOptionSlice";
import { getOpportunityById } from "../../store/reducer/opportunity/opportunitySlice";

import {
  deleteBomItem,
  generateBom,
  getBomByDrawingId,
  getGenerateBomStatus,
  updateDrawingBom,
} from "../../store/reducer/drawing/drawingBomSlice";
import {
  getItemById,
  getItemsForUi,
} from "../../store/reducer/items/itemSlice";
import { getItemPriceByItemId } from "../../store/reducer/itemPrices/itemPriceSlice";
import { getDrawingById } from "../../store/reducer/drawing/drawingSlice";
import Notes from "../Notes/Notes";
import { proposalStatusByDrawingId } from "../../store/reducer/drawing/drawingProposalSlice";
import {  getProposalFinalStatus } from "../../store/reducer/drawing/drawingFileSilce";
import { deletePandLOnChange } from "../../store/reducer/drawing/drawingPandLSlice";
import { deleteInstallOnChange } from "../../store/reducer/drawing/drawingInstallSlice";
import { deleteProposalOnChange } from "../../store/reducer/drawing/drawingFileSilce";

function EditDrawingBom() {
  const { id, drawing_id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const [opportunityData, setOpportunityData] = useState([]);
  const [drawingData, setdrawingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [bomData, setBomData] = useState([]);
  const [proposalStatus, setProposalStatus] = useState(true);
  const [selectedItemPrice, setSelectedItemPrice] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [isProposalFinalised, setIsProposalFinalised] = useState(false);

  const { items = [] } = useSelector((state) => state.items);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const ErrorMessage = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? (
          <div className="text-red-700">{error}</div>
        ) : null;
      }}
    />
  );

  useEffect(() => {
    dispatch(getProposalFinalStatus(drawing_id)).then(result => {
      if(result?.payload?.isProposalFinalised) {
        setIsProposalFinalised(true);
      }
    })
    dispatch(getOpportunityById(id)).then((result) => {
      if (result.payload) {
        setOpportunityData(result.payload);
      }
    });
    dispatch(getDrawingById(drawing_id)).then((result) => {
      if (result.payload) {
        setdrawingData(result.payload);

        dispatch(proposalStatusByDrawingId(result.payload.uniqueId)).then(
          (result) => {
            if (result.payload) {
              setProposalStatus(result.payload.hasData);
            } else {
              setProposalStatus(false);
            }
          }
        );
      }
    });

    dispatch(getItemsForUi());

    dispatch(getBomByDrawingId(drawing_id)).then((result) => {
      if (result.payload) {
        setBomData(result.payload);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);

  const handleGenerateBom = () => {
    setLoading(true);
    dispatch(generateBom(drawing_id)).then((result) => {
      if (result.payload) {
        dispatch(proposalStatusByDrawingId(drawingData.drawing_uniqueId)).then(
          (result) => {
            if (result.payload) {
              setProposalStatus(result.payload.hasData);
            } else {
              setProposalStatus(result.payload.hasData);
            }
          }
        );
        dispatch(getBomByDrawingId(drawing_id)).then((result) => {
          if (result.payload) {
            setBomData(result.payload);
            setLoading(false);
          }
        });
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  const validationSchema = Yup.object().shape({
    bom_items: Yup.array()
      .of(
        Yup.object().shape({
          item_id: Yup.string().required("please select item"),
          qty: Yup.number().required("required").integer(),
          item_length: Yup.number().required("required").positive("positive"),
          revised_qty: Yup.number("must be number")
            .required("required")
            .positive("must be positive number"),
        })
      )
      .required("Must have area"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.drawing_id = drawing_id;
    values.drawing_uniqueId = drawingData.uniqueId;
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;
    const hasItemChanged = (originalItem, updatedItem) => {
      return Object.keys(updatedItem).some((key) => {
        // Skip the updatedAt field
        if (key === "updatedAt") return false;
    
        // Handle null/undefined safely
        const originalValue = originalItem[key] ?? "";
        const updatedValue = updatedItem[key] ?? "";
    
        // For objects/arrays, do deep comparison via JSON.stringify
        if (typeof originalValue === "object" || typeof updatedValue === "object") {
          return JSON.stringify(originalValue) !== JSON.stringify(updatedValue);
        }
    
        // Primitive type comparison (numbers, strings, booleans)
        return originalValue !== updatedValue;
      });
    };    
    // Compare current values with the original bomData
    const updatedBomItems = values.bom_items.map((item, index) => {
      const originalItem = bomData[activeTab]?.bom_items[index];

      // Set UPDATE_FLAG to 1 only if there is a change
      const isChanged = originalItem ? hasItemChanged(originalItem, item) : false;

      return {
        ...item,
        UPDATE_FLAG: isChanged ? 1 : item.UPDATE_FLAG || 0 // Keep existing flag if not changed
      };
    });

    // Update the values with the modified BOM items
    values.bom_items = updatedBomItems;
    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      if (fieldName === "bom_items") {
        formData.append(fieldName, JSON.stringify(values[fieldName]));
      } else {
        formData.append(fieldName, values[fieldName]);
      }
    });
    // Wait for the delete action to complete
    let deleteResult = await dispatch(deletePandLOnChange(drawing_id));
    if (!deleteResult.payload) {
      toast.error("Failed to delete pandl data.");
      setSubmitting(false);
      return;
    }
    
    deleteResult = await dispatch(deleteInstallOnChange(drawing_id));
    if (!deleteResult.payload) {
      toast.error("Failed to delete install data.");
      setSubmitting(false);
      return;
    }
  
    deleteResult = await dispatch(deleteProposalOnChange(drawing_id));
    if (!deleteResult.payload) {
      toast.error("Failed to delete proposal data.");
      setSubmitting(false);
      return;
    }
    
    await dispatch(updateDrawingBom({ data: formData, drawing_id })).then(
      (result) => {
        if (result.payload) {
          dispatch(getBomByDrawingId(drawing_id)).then((result) => {
            if (result.payload) {
              setBomData(result.payload);
            }
          });
          toast.success(result.payload.message);
        } else {
          toast.error(result.error.message);
        }
      }
    );
  };

  const handleDeleteItem = async (id) => {
    // Wait for the delete action to complete
    let deleteResult = await dispatch(deletePandLOnChange(drawing_id));
    if (!deleteResult.payload) {
      toast.error("Failed to delete pandl data.");
      return;
    }
    
    deleteResult = await dispatch(deleteInstallOnChange(drawing_id));
    if (!deleteResult.payload) {
      toast.error("Failed to delete install data.");
      return;
    }
  
    deleteResult = await dispatch(deleteProposalOnChange(drawing_id));
    if (!deleteResult.payload) {
      toast.error("Failed to delete proposal data.");
      return;
    }
    
    await dispatch(deleteBomItem({ id })).then((result) => {
      if (result.payload) {
        dispatch(getBomByDrawingId(drawing_id)).then((result) => {
          if (result.payload) {
            setBomData(result.payload);
          }
        });
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <>
      <div className="block md:grid grid-cols-3 items-center mb-[30px]">
        <div className="flex gap-[16px] items-center mb-[32px] md:mb-0">
          <Link to={`/opportunity/${id}`}>
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Drawing BOM / Edit
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              BOM
            </span>
          </div>
        </div>
        <div className="font-Inter font-[500] leading-[30px] text-[18px] text-center">
          {opportunityData.id}-{opportunityData.opportunity_name}
        </div>
        <Notes type="drawing-boms" drawing_id={drawing_id} />
      </div>
      <div className="flex gap-[30px] bg-[#61b35436] p-2 rounded-lg mb-10 overflow-auto text-nowrap justify-between">
        <Link to={`/opportunity/${id}/drawing/${drawing_id}`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Drawing Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/site`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/site`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Site Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/take-off`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/take-off`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Take Off
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/bom`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/bom`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            BOM
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/install`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/install`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Install
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/additional-options`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/additional-options`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Additional Options
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/discount`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/discount`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Discount
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/pandl`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/pandl`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            P&L
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/proposal`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/proposal`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Proposal
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/files`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/files`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Files
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Create PO
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            PO History
          </div>
        </Link>
      </div>
      {!loading ? (
        <>
          <div className="border border-gray-200 p-4 rounded-lg">
            <div className="grid grid-cols-5 gap-[20px] justify-between mb-2">
              <div className="col-span-4">
                <div className="flex gap-[30px] p-2 text-nowrap overflow-x-scroll">
                  {bomData.length ? (
                    bomData.map((bom, index) => {
                      return (
                        <span
                          className={`flex justify-center border-b-4 py-2 px-5 cursor-pointer ${
                            activeTab === index
                              ? "text-[#55A14A] border-b-4 border-[#55A14A]"
                              : "text-black border-transparent hover:border-b-4 hover:border-[#55A14A] hover:text-[#55A14A]"
                          }`}
                          key={index}
                          onClick={() => handleTabClick(index)}
                          data_id={index}
                        >
                          {bom.area_name}
                        </span>
                      );
                    })
                  ) : (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  )}
                </div>
              </div>
              {!proposalStatus && !isProposalFinalised && (
                <div className="grid-cols-1">
                  <button onClick={handleGenerateBom}>
                    <div className="py-[10px] px-[40px] font-[500] font-Inter text-[18px] text-center text-[#17171B] rounded-[12px] text-[#55A14A] border border-[#55A14A]">
                      <span>Generate BOM</span>
                    </div>
                  </button>
                </div>
              )}
            </div>

            {bomData.length ? (
              bomData
                .filter((bom, index) => index === activeTab)
                .map((bom, index) => {
                  let initialValues = {
                    area_id: `${bom.area_id}`,
                    area_uniqueId: `${bom.area_uniqueId}`,
                    area_name: `${bom.area_name}`,
                    bom_items: bom.bom_items.length
                      ? bom.bom_items
                      : [
                          {
                            id: "",
                            uniqueId: "",
                            item_id: "",
                            extra_details: "",
                            qty: "",
                            item_length: "",
                            revised_qty: "",
                            item_cost: "",
                            total_line_cost: "",
                            bom_supplier_id: "",
                            item_price: "",
                            total_line_price: "",
                            price_factor: "",
                            UPDATE_FLAG: 0,
                          },
                        ],
                  };
                  return (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                      key={bom.area_id}
                    >
                      {({
                        isSubmitting,
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleSubmit,
                      }) => (
                        <Form>
                          <FieldArray name="bom_items">
                            {({ insert, remove, push }) => {
                              const bomItemsIdSet = new Set(
                                values?.bom_items?.map(
                                  ({ item_id }) => +item_id
                                ) || []
                              );

                              return (
                                <div className="overflow-x-auto">
                                  <div className="overflow-x-scroll">
                                    <div className="sm:rounded-lg mt-5 w-full min-w-[2000px]">
                                      <div className="grid grid-cols-12 gap-[20px] bg-[#F8F8F8]">
                                        <div className="col-span-4 px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider ">
                                          Item name
                                        </div>
                                        <div className="px-2 py-3 col-span-2 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                                          Extra Details
                                        </div>
                                        <div className="flex  gap-[20px] col-span-3  justify-evenly">
                                          <div className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                                            Quantity
                                          </div>
                                          <div className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                                            Length
                                          </div>
                                          <div className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                                            Total Qty
                                          </div>
                                          <div className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                                            Cost
                                          </div>
                                        </div>
                                        <div className="flex gap-[20px] col-span-3  justify-evenly">
                                          <div className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                                            Line Cost 
                                            <p>({new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(
                                              values?.bom_items?.reduce((acc, it) => acc + (Number(it.total_line_cost) || 0), 0)
                                            )})</p>
                                          </div>
                                          <div className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                                            Item Price
                                          </div>
                                          <div className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                                            Line Price
                                            <p>({new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(
                                              values?.bom_items?.reduce((acc, it) => acc + (Number(it.total_line_price) || 0), 0)
                                            )})</p>
                                          </div>
                                          <div className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                                            Options
                                          </div>
                                        </div>
                                      </div>
                                      {values.bom_items.length > 0 &&
                                        values.bom_items.map((bom, index) => {
                                          return (
                                            <div
                                              className="grid grid-cols-12 gap-[20px] mt-5 p-1"
                                              key={index}
                                              style={{
                                                backgroundColor: bom.total_line_cost == 0 ? '#ffcccc' : 'transparent' // Light red
                                              }}                                                                                         
                                            >
                                              <div className="col-span-4">
                                                <div className="relative">
                                                  <Field
                                                    as="select"
                                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                    id={`bom_items.${index}.item_id`}
                                                    name={`bom_items.${index}.item_id`}
                                                    disabled={bom.uniqueId}
                                                    onChange={(e) => {
                                                      setFieldValue(
                                                        `bom_items.${index}.item_id`,
                                                        e.target.value
                                                      );
                                                      // setFieldValue(
                                                      //   `bom_items.${index}.price_factor`,
                                                      //   parseFloat(
                                                      //     values.price_factor
                                                      //   )
                                                      // );
                                                      dispatch(
                                                        getItemById(
                                                          e.target.value
                                                        )
                                                      ).then((result) => {
                                                        if (result.payload) {
                                                          setSelectedItem(
                                                            result.payload
                                                          );
                                                          setFieldValue(
                                                            `bom_items.${index}.price_factor`,
                                                            parseFloat(
                                                              result.payload
                                                                .price_factor
                                                            )
                                                          );
                                                          const items =
                                                            result.payload;
                                                          setFieldValue(
                                                            `bom_items.${index}.item_length`,
                                                            result.payload
                                                              .length_of_material ||
                                                              0
                                                          );
                                                          setFieldValue(
                                                            `bom_items.${index}.bom_supplier_id`,
                                                            3
                                                          );
                                                          dispatch(
                                                            getItemPriceByItemId(
                                                              e.target.value
                                                            )
                                                          ).then((result) => {
                                                            if (
                                                              result.payload
                                                            ) {
                                                              if (
                                                                result.payload
                                                                  .unit_price ===
                                                                0
                                                              ) {
                                                                toast.error(
                                                                  "Price is zero"
                                                                );
                                                              }
                                                              setSelectedItemPrice(
                                                                result.payload
                                                              );
                                                              setFieldValue(
                                                                `bom_items.${index}.item_cost`,
                                                                (
                                                                  Math.round(
                                                                    result
                                                                      .payload
                                                                      .unit_price *
                                                                      100
                                                                  ) / 100
                                                                ).toFixed(2)
                                                              );

                                                              let item_price = (
                                                                Math.round(
                                                                  result.payload
                                                                    .unit_price *
                                                                    parseFloat(
                                                                      items.price_factor
                                                                    ) *
                                                                    100
                                                                ) / 100
                                                              ).toFixed(2);
                                                              setFieldValue(
                                                                `bom_items.${index}.item_price`,
                                                                item_price
                                                              );
                                                            } else {
                                                              toast.error(
                                                                "Price not found"
                                                              );
                                                            }
                                                          });
                                                        } else {
                                                          toast.error(
                                                            "Item not found"
                                                          );
                                                        }
                                                      });
                                                    }}
                                                  >
                                                    <option value={""}>
                                                      Select Item
                                                    </option>
                                                    {items
                                                      .filter(
                                                        ({ group_id, id }) =>
                                                          (group_id === 425 ||
                                                            group_id === 427 ||
                                                            group_id === 528 ||
                                                            group_id === 429 ||
                                                            group_id === 430 ||
                                                            group_id === 431 ||
                                                            group_id === 432 ||
                                                            group_id === 433 ||
                                                            group_id === 434 ||
                                                            group_id === 436 ||
                                                            group_id === 438) &&
                                                          (bom.item_id ||
                                                            !bomItemsIdSet.has(
                                                              +id
                                                            ))
                                                      )
                                                      .map((item) => (
                                                        <option
                                                          value={item.id}
                                                          key={item.id}
                                                        >
                                                          {
                                                            item.item_description
                                                          }
                                                        </option>
                                                      ))}
                                                  </Field>
                                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                    <svg
                                                      className="fill-current h-4 w-4"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 20 20"
                                                    >
                                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                    </svg>
                                                  </div>
                                                </div>
                                                <ErrorMessage
                                                  name={`bom_items.${index}.item_id`}
                                                />
                                              </div>
                                              <div className="col-span-2">
                                                <Field
                                                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-2 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-center"
                                                  id={`bom_items.${index}.extra_details`}
                                                  name={`bom_items.${index}.extra_details`}
                                                ></Field>
                                                <ErrorMessage
                                                  name={`bom_items.${index}.extra_details`}
                                                />
                                              </div>
                                              <div className="flex gap-[20px] col-span-3">
                                                <div>
                                                  <Field
                                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-2 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-center"
                                                    id={`bom_items.${index}.qty`}
                                                    name={`bom_items.${index}.qty`}
                                                    onChange={(e) => {
                                                      let qty = e.target.value;
                                                      setFieldValue(
                                                        `bom_items.${index}.qty`,
                                                        e.target.value
                                                      );

                                                      let itemCost = parseFloat(
                                                        values.bom_items[index]
                                                          .item_cost || 0
                                                      );
                                                      let itemPrice =
                                                        parseFloat(
                                                          values.bom_items[
                                                            index
                                                          ].item_price || 0
                                                        );

                                                      let result = items.filter(
                                                        (item) =>
                                                          item.id ===
                                                          parseInt(
                                                            values.bom_items[
                                                              index
                                                            ].item_id,
                                                            10
                                                          )
                                                      );
                                                      if (
                                                        result[0].uom === 442
                                                      ) {
                                                        let itemLength =
                                                          parseFloat(
                                                            values.bom_items[
                                                              index
                                                            ].item_length || 0
                                                          );
                                                        let revQty = parseFloat(
                                                          qty * itemLength
                                                        ).toFixed(2);
                                                        setFieldValue(
                                                          `bom_items.${index}.revised_qty`,
                                                          revQty
                                                        );

                                                        let totalLineCost =
                                                          parseFloat(
                                                            revQty * itemCost
                                                          ).toFixed(2);

                                                        let totalLinePrice =
                                                          parseFloat(
                                                            revQty * itemPrice
                                                          ).toFixed(2);

                                                        setFieldValue(
                                                          `bom_items.${index}.total_line_cost`,
                                                          totalLineCost
                                                        );
                                                        setFieldValue(
                                                          `bom_items.${index}.total_line_price`,
                                                          totalLinePrice
                                                        );
                                                      } else {
                                                        let revQty = qty;
                                                        setFieldValue(
                                                          `bom_items.${index}.revised_qty`,
                                                          revQty
                                                        );

                                                        let totalLineCost =
                                                          parseFloat(
                                                            revQty * itemCost
                                                          ).toFixed(2);

                                                        let totalLinePrice =
                                                          parseFloat(
                                                            revQty * itemPrice
                                                          ).toFixed(2);

                                                        setFieldValue(
                                                          `bom_items.${index}.total_line_cost`,
                                                          totalLineCost
                                                        );
                                                        setFieldValue(
                                                          `bom_items.${index}.total_line_price`,
                                                          totalLinePrice
                                                        );
                                                      }
                                                    }}
                                                  ></Field>
                                                  <ErrorMessage
                                                    name={`bom_items.${index}.qty`}
                                                  />
                                                </div>
                                                <div>
                                                  <Field
                                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-2 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-center"
                                                    id={`bom_items.${index}.item_length`}
                                                    name={`bom_items.${index}.item_length`}
                                                    onChange={(e) => {
                                                      const itemLength =
                                                        e.target.value;
                                                      setFieldValue(
                                                        `bom_items.${index}.item_length`,
                                                        e.target.value
                                                      );
                                                      const qty = parseFloat(
                                                        values.bom_items[index]
                                                          .qty || 0
                                                      );
                                                      const itemCost =
                                                        parseFloat(
                                                          values.bom_items[
                                                            index
                                                          ].item_cost || 0
                                                        );
                                                      const itemPrice =
                                                        parseFloat(
                                                          values.bom_items[
                                                            index
                                                          ].item_price || 0
                                                        );

                                                      let result = items.filter(
                                                        (item) =>
                                                          item.id ===
                                                          parseInt(
                                                            values.bom_items[
                                                              index
                                                            ].item_id,
                                                            10
                                                          )
                                                      );
                                                      if (
                                                        result[0].uom === 442
                                                      ) {
                                                        let revQty = (
                                                          parseFloat(qty || 0) *
                                                          itemLength
                                                        ).toFixed(2);

                                                        setFieldValue(
                                                          `bom_items.${index}.revised_qty`,
                                                          revQty
                                                        );

                                                        let totalLineCost = (
                                                          parseFloat(revQty) *
                                                          itemCost
                                                        ).toFixed(2);

                                                        let totalLinePrice = (
                                                          parseFloat(revQty) *
                                                          itemPrice
                                                        ).toFixed(2);

                                                        setFieldValue(
                                                          `bom_items.${index}.total_line_cost`,
                                                          totalLineCost
                                                        );
                                                        setFieldValue(
                                                          `bom_items.${index}.total_line_price`,
                                                          totalLinePrice
                                                        );
                                                      } else {
                                                        let revQty = parseFloat(
                                                          qty || 0
                                                        ).toFixed(2);

                                                        setFieldValue(
                                                          `bom_items.${index}.revised_qty`,
                                                          revQty
                                                        );

                                                        const itemCost =
                                                          parseFloat(
                                                            values.bom_items[
                                                              index
                                                            ].item_cost || 0
                                                          );
                                                        const itemPrice =
                                                          parseFloat(
                                                            values.bom_items[
                                                              index
                                                            ].item_price || 0
                                                          );

                                                        const totalLineCost = (
                                                          parseFloat(
                                                            values.bom_items[
                                                              index
                                                            ].revised_qty
                                                          ) * itemCost
                                                        ).toFixed(2);

                                                        const totalLinePrice = (
                                                          parseFloat(
                                                            values.bom_items[
                                                              index
                                                            ].revised_qty
                                                          ) * itemPrice
                                                        ).toFixed(2);

                                                        setFieldValue(
                                                          `bom_items.${index}.total_line_cost`,
                                                          totalLineCost
                                                        );
                                                        setFieldValue(
                                                          `bom_items.${index}.total_line_price`,
                                                          totalLinePrice
                                                        );
                                                      }
                                                    }}
                                                  ></Field>
                                                  <ErrorMessage
                                                    name={`bom_items.${index}.item_length`}
                                                  />
                                                </div>

                                                <div>
                                                  <Field
                                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-2 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-center"
                                                    id={`bom_items.${index}.revised_qty`}
                                                    name={`bom_items.${index}.revised_qty`}
                                                    disabled
                                                    onChange={(e) => {
                                                      const revisedQty =
                                                        e.target.value;
                                                      setFieldValue(
                                                        `bom_items.${index}.revised_qty`,
                                                        revisedQty
                                                      );
                                                    }}
                                                  ></Field>
                                                  <ErrorMessage
                                                    name={`bom_items.${index}.revised_qty`}
                                                  />
                                                </div>

                                                <div>
                                                  <Field
                                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-2 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-center"
                                                    id={`bom_items.${index}.item_cost`}
                                                    name={`bom_items.${index}.item_cost`}
                                                    onChange={(e) => {
                                                      const itemCost =
                                                        e.target.value;

                                                      setFieldValue(
                                                        `bom_items.${index}.item_cost`,
                                                        e.target.value
                                                      );
                                                      const revisedQty =
                                                        parseFloat(
                                                          values.bom_items[
                                                            index
                                                          ].revised_qty || 0
                                                        );
                                                      const itemPrice = (
                                                        parseFloat(
                                                          e.target.value || 0
                                                        ) *
                                                        values.bom_items[index]
                                                          .price_factor
                                                      ).toFixed(2);

                                                      const totalLineCost = (
                                                        parseFloat(
                                                          revisedQty || 0
                                                        ) * itemCost
                                                      ).toFixed(2);

                                                      const totalLinePrice = (
                                                        parseFloat(
                                                          revisedQty || 0
                                                        ) * itemPrice
                                                      ).toFixed(2);

                                                      setFieldValue(
                                                        `bom_items.${index}.item_price`,
                                                        itemPrice
                                                      );

                                                      setFieldValue(
                                                        `bom_items.${index}.total_line_cost`,
                                                        totalLineCost
                                                      );
                                                      setFieldValue(
                                                        `bom_items.${index}.total_line_price`,
                                                        totalLinePrice
                                                      );
                                                    }}
                                                  ></Field>
                                                </div>
                                              </div>
                                              <div className="flex gap-[20px] col-span-3">
                                                <div>
                                                  <Field
                                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-2 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-center"
                                                    id={`bom_items.${index}.total_line_cost`}
                                                    name={`bom_items.${index}.total_line_cost`}
                                                    disabled
                                                  ></Field>
                                                </div>
                                                <div>
                                                  <Field
                                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-2 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-center"
                                                    id={`bom_items.${index}.item_price`}
                                                    name={`bom_items.${index}.item_price`}
                                                    disabled
                                                  ></Field>
                                                </div>
                                                <div>
                                                  <Field
                                                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-2 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-center"
                                                    id={`bom_items.${index}.total_line_price`}
                                                    name={`bom_items.${index}.total_line_price`}
                                                    disabled
                                                  ></Field>
                                                </div>
                                                {values.bom_items.length >
                                                  1 && (
                                                  <div className="py-2 text-center">
                                                    {!isProposalFinalised && (
                                                      <span
                                                        className="text-red-400 hover:text-red-700 p-3 bg-white rounded-lg cursor-pointer"
                                                        onClick={() => {
                                                          if (
                                                            values.bom_items[
                                                              index
                                                            ].uniqueId
                                                          ) {
                                                            handleDeleteItem(
                                                              values.bom_items[
                                                                index
                                                              ].id
                                                            );
                                                          }
                                                          remove(index);
                                                        }}
                                                      >
                                                        <FontAwesomeIcon
                                                          icon={faTrash}
                                                          size="xl"
                                                        />
                                                      </span>
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                  <div className="text-right p-[16px]">
                                    {!isProposalFinalised && (
                                      <span
                                        className="cursor-pointer text-[#55A14A]"
                                        onClick={() =>
                                          push({
                                            id: "",
                                            uniqueId: "",
                                            item_id: "",
                                            qty: "",
                                            item_length: "",
                                            revised_qty: "",
                                            item_cost: "",
                                            total_line_cost: "",
                                            bom_supplier_id: "",
                                            item_price: "",
                                            total_line_price: "",
                                            extra_details: "",
                                          })
                                        }
                                      >
                                        + Add New Item
                                      </span>
                                    )}
                                  </div>
                                </div>
                              );
                            }}
                          </FieldArray>

                          {!isProposalFinalised && (
                            <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                              <div>
                                <button
                                  type="submit"
                                  name="submit"
                                  className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                                    isSubmitting || proposalStatus
                                      ? "bg-gray-300"
                                      : "bg-[#1C1C20]"
                                  }`}
                                  disabled={isSubmitting || proposalStatus}
                                >
                                  {isSubmitting ? (
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                  ) : (
                                    "Save"
                                  )}
                                </button>
                              </div>
                            </div>
                          )}
                        </Form>
                      )}
                    </Formik>
                  );
                })
            ) : (
              <div className="text-center">
                <FontAwesomeIcon
                  icon={faSpinner}
                  size="2xl"
                  style={{ color: "#55A14A" }}
                  spin
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="text-center">
          {/* <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          /> */}
          <span>No data available</span>
        </div>
      )}
    </>
  );
}

export default EditDrawingBom;
