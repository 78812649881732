import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getOpportunityById } from "../../store/reducer/opportunity/opportunitySlice";
import { getDiscountByDrawingId } from "../../store/reducer/drawing/drawingDiscountSlice";
import { getDrawingById } from "../../store/reducer/drawing/drawingSlice";
import {
  getPandLByDrawingId,
  getGeneratePandLStatus,
  generatePandL,
} from "../../store/reducer/drawing/drawingPandLSlice";
import { generateBom } from "../../store/reducer/drawing/drawingBomSlice"
import { generateInstall } from "../../store/reducer/drawing/drawingInstallSlice"
import { generateProposal } from "../../store/reducer/drawing/drawingProposalSlice"
import { proposalStatusByDrawingId } from "../../store/reducer/drawing/drawingProposalSlice";
import {  getProposalFinalStatus } from "../../store/reducer/drawing/drawingFileSilce";
import Notes from "../Notes/Notes";

function formatCurrency(amount) {
  return new Intl.NumberFormat('en-GB', { 
      style: 'currency', 
      currency: 'GBP' 
  }).format(amount);
}

function EditDrawingPandL() {
  const { id, drawing_id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const [opportunityData, setOpportunityData] = useState([]);
  const [drawingData, setdrawingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(3);
  const [pandlData, setPandlData] = useState([]);
  const [supplierListData, setSupplierListData] = useState([]);
  const [generatePandLStatus, setGeneratePandLStatus] = useState(false);
  const [discountData, setDiscountData] = useState([]);
  const [proposalStatus, setProposalStatus] = useState(true);
  const [isProposalFinalised, setIsProposalFinalised] = useState(false);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    dispatch(getProposalFinalStatus(drawing_id)).then(result => {
      if(result?.payload?.isProposalFinalised) {
        setIsProposalFinalised(true);
      }
    })
    dispatch(getOpportunityById(id)).then((result) => {
      if (result.payload) {
        setOpportunityData(result.payload);
      }
    });

    dispatch(getDrawingById(drawing_id)).then((result) => {
      if (result.payload) {
        setdrawingData(result.payload);
        dispatch(proposalStatusByDrawingId(result.payload.uniqueId)).then(
          (result) => {
            if (result.payload) {
              setProposalStatus(result.payload.hasData);
            } else {
              setProposalStatus(false);
            }
          }
        );
      }
    });

    dispatch(getDiscountByDrawingId(drawing_id)).then((result) => {
      if (result.payload) {
        setDiscountData(result.payload);
      }
    });

    dispatch(getPandLByDrawingId(drawing_id)).then((result) => {
      if (result.payload) {
        setSupplierListData(result.payload.supplierListData);
        setPandlData(result.payload.pandlData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });

    dispatch(getGeneratePandLStatus(drawing_id)).then((result) => {
      if (result.payload.hasData) {
        setGeneratePandLStatus(true);
      } else {
        setGeneratePandLStatus(false);
      }
    });
  }, []);

  const handleGeneratePandL = async () => {
    try {
      setLoading(true);
  
      const userSession = JSON.parse(localStorage.getItem("userSession"));
      if (!userSession?.id) throw new Error("User session is invalid");
  
      if (!drawing_id) throw new Error("Drawing ID is required");
      if (!drawingData?.uniqueId) throw new Error("Drawing data is missing");
  
      // Step 1: Generate P&L
      let result = await dispatch(generatePandL(drawingData.uniqueId));
      if (!result.payload) throw new Error(`Generate P&L failed: ${result.error.message}`);
  
      // Step 2: Generate Proposal
      const proposalData = {
        drawing_id,
        document_of: 456,
        document_type: 461,
        file_name: "Pricing Matrix",
        staff_id: userSession.id,
      };
  
      result = await dispatch(
        generateProposal({ drawing_uniqueId: drawingData.uniqueId, data: proposalData })
      );
      if (!result.payload) throw new Error(`Generate Proposal failed: ${result.error.message}`);
  
      toast.success("All proposal steps executed successfully");
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  let totalCost = 0;
  let totalSell = 0;
  let totalMargin = 0;
  let totalDiscountedPrice = 0;

  return (
    <>
      <div className="block md:grid grid-cols-3 items-center mb-[30px]">
        <div className="flex gap-[16px] items-center mb-[32px] md:mb-0">
          <Link to={`/opportunity/${id}`}>
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Drawing P&L / Edit
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              P&L
            </span>
          </div>
        </div>
        <div className="font-Inter font-[500] leading-[30px] text-[18px] text-center">
          {opportunityData.id}-{opportunityData.opportunity_name}
        </div>
        <Notes type="drawing-pandl" drawing_id={drawing_id} />
      </div>
      <div className="flex gap-[30px] bg-[#61b35436] p-2 rounded-lg mb-10 overflow-auto text-nowrap justify-between">
        <Link to={`/opportunity/${id}/drawing/${drawing_id}`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Drawing Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/site`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/site`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Site Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/take-off`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/take-off`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Take Off
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/bom`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/bom`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            BOM
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/install`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/install`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Install
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/additional-options`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/additional-options`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Additional Options
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/discount`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/discount`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Discount
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/pandl`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/pandl`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            P&L
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/proposal`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/proposal`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Proposal
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/files`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/files`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Files
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Create PO
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            PO History
          </div>
        </Link>
      </div>
      {!loading ? (
        <>
          <div className="border border-gray-200 p-4 rounded-lg overflow-auto">
            <div className="flex gap-[20px] justify-between mb-2">
              <div className="col-span-4">
                <div className="flex gap-[30px] p-2 text-nowrap overflow-x-scroll">
                  {generatePandLStatus ? (
                    supplierListData.length ? (
                      supplierListData
                        .sort((a, b) => 
                          a.bom_supplier_id === 3 ? -1 : 1
                        )
                        .map((supplierList) => {
                          return (
                            <span
                              className={`flex justify-center border-b-4 py-2 px-5 cursor-pointer ${
                                activeTab === supplierList.bom_supplier_id
                                  ? "text-[#55A14A] border-b-4 border-[#55A14A]"
                                  : "text-black border-transparent hover:border-b-4 hover:border-[#55A14A] hover:text-[#55A14A]"
                              }`}
                              key={supplierList.bom_supplier_id}
                              onClick={() =>
                                handleTabClick(supplierList.bom_supplier_id)
                              }
                              data_id={supplierList.bom_supplier_id}
                            >
                              {supplierList.supplier_name}
                            </span>
                          );
                        })
                    ) : (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    )
                  ) : (
                    "No Data available"
                  )}
                </div>
              </div>
              {!proposalStatus && !isProposalFinalised && (
                <div className="grid-cols-1">
                  <button onClick={handleGeneratePandL}>
                    <div className="py-[10px] px-[40px] font-[500] font-Inter text-[18px] text-center text-[#17171B] rounded-[12px] text-[#55A14A] border border-[#55A14A]">
                      <span>Generate P&L</span>
                    </div>
                  </button>
                </div>
              )}
            </div>

            {generatePandLStatus && (
              <div className="w-[800px]">
                <div className="grid grid-cols-8 gap-[30px] justify-between bg-[#F8F8F8] p-3 rounded mb-4">
                  <div>No.</div>
                  <div className="col-span-2">Type</div>
                  <div>Cost</div>
                  <div>Selling Price</div>
                  <div>Discount</div>
                  <div>Discounted Price</div>
                  <div>Margin</div>
                </div>
                {pandlData.length ? (
                  pandlData
                    .filter((pandl, index) => pandl.bom_supplier_id === activeTab)
                    .map((pandl, index) => {
                      totalCost = totalCost + Number(pandl.total_cost);
                      totalSell = totalSell + Number(pandl.total_price);
                      totalDiscountedPrice = totalDiscountedPrice + Number(pandl.total_sell_with_discount)

                      return (
                        <div className="grid grid-cols-8 gap-[30px] items-center p-5 mb-2" key={index}>
                          <div>{index + 1}</div>
                          <div className="col-span-2">{pandl.item_group_name}</div>
                          <div>{formatCurrency(parseFloat(pandl.total_cost).toFixed(2))}</div>
                          <div>{formatCurrency(parseFloat(pandl.total_price).toFixed(2))}</div>
                          <div>{formatCurrency(parseFloat(pandl.total_discount).toFixed(2))}</div>
                          <div>{formatCurrency(parseFloat(pandl.total_sell_with_discount).toFixed(2))}</div>
                          <div>{parseFloat(pandl.total_margin_with_discount).toFixed(2)}%</div>
                        </div>
                      );
                    })
                ) : (
                  <div className="text-center">
                    <FontAwesomeIcon icon={faSpinner} size="2xl" style={{ color: "#55A14A" }} spin />
                  </div>
                )}

                <div className="border b-[#F8F8F8] rounded-lg p-5">
                  <div className="grid grid-cols-8 gap-[30px] mb-3">
                    <div className="font-[600] col-span-3">Totals</div>
                    <div className="font-[600] col-span-1">{formatCurrency(parseFloat(totalCost).toFixed(2))}</div>
                    <div className="font-[600] col-span-2">{formatCurrency(parseFloat(totalSell).toFixed(2))}</div>
                    <div className="font-[600]">{formatCurrency(parseFloat(totalDiscountedPrice).toFixed(2))}</div>
                    <div className="font-[600]">{parseFloat(((totalDiscountedPrice - totalCost) / totalDiscountedPrice) * 100).toFixed(2)}%</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
          <h2 className="font-Inter font-[500] leading-[30px] text-[20px] m-4">Execution started, it might take a while...</h2>
        </div>
      )}
    </>
  );
}

export default EditDrawingPandL;
