import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const generateBom = createAsyncThunk("generateBom", async (data) => {
  const request = await api(`api/admin/generatebom/${data}`, "", "get");
  return request;
});

export const getBomByDrawingId = createAsyncThunk(
  "getBomByDrawingId",
  async (data) => {
    const request = await api(`api/admin/bom/${data}`, "", "get");
    return request;
  }
);

export const getGenerateBomStatus = createAsyncThunk(
  "getGenerateBomStatus",
  async (data) => {
    const request = await api(`api/admin/generatebomstatus/${data}`, "", "get");
    return request;
  }
);

export const updateDrawingBom = createAsyncThunk(
  "updateDrawingBom",
  async ({ data, drawing_id }) => {
    const request = await api(`api/admin/bom/${drawing_id}`, data, "post");
    return request;
  }
);

export const deleteBomItem = createAsyncThunk(
  "deleteBomItem",
  async ({ id }) => {
    const request = await api(`api/admin/bom/${id}`, {}, "delete");
    return request;
  }
);

export const deleteBomOnChange = createAsyncThunk("deleteBomOnChange", async (drawing_id) => {
  const request = await api(`api/admin/deletebomonchange/${drawing_id}`, "", "get");
  return request;
});

const drawingBomSlice = createSlice({
  name: "drawingBom",
  initialState: {
    loading: false,
    bom: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(generateBom.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateBom.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(generateBom.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateDrawingBom.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDrawingBom.fulfilled, (state, action) => {
        state.loading = false;
        state.bom = state.bom.map((option) => {
          if (option.id === action.payload.id) {
            return action.payload;
          }
          return option;
        });
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(updateDrawingBom.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(deleteBomItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteBomItem.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(deleteBomItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default drawingBomSlice.reducer;
