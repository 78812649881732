import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const getAllFiles = createAsyncThunk("getAllFiles", async (data) => {
  const request = await api(`api/admin/files/${data}`, "", "get");
  return request;
});

export const getFinalProposalFiles = createAsyncThunk(
  "getFinalProposalFiles",
  async (data) => {
    const request = await api(`api/admin/finalproposal/${data}`, "", "get");
    return request;
  }
);

export const drawingandproposalfiles = createAsyncThunk(
  "drawingandproposalfiles",
  async (data) => {
    const request = await api(
      `api/admin/drawingandproposalfiles/${data}`,
      "",
      "get"
    );
    return request;
  }
);

export const isFinalFile = createAsyncThunk(
  "isFinalFile",
  async ({ data, file_id }) => {
    const request = await api(
      `api/admin/isfinalfiles/${file_id}`,
      data,
      "post"
    );
    return request;
  }
);

export const getProposalFinalStatus = createAsyncThunk(
  "getProposalFinalStatus",
  async (drawing_id) => {
    const request = await api(
      `api/admin/getproposalfinalstatus/${drawing_id}`,
      {},
      "get"
    );
    return request;
  }
);

export const deleteProposalOnChange = createAsyncThunk("deleteProposalOnChange", async (drawing_id) => {
  const request = await api(`api/admin/deleteproposalonchange/${drawing_id}`, "", "get");
  return request;
});

export const addFile = createAsyncThunk(
  "addFile",
  async ({ data, drawing_id }) => {
    const request = await api(`api/admin/file/${drawing_id}`, data, "postFile");
    return request;
  }
);

export const deleteFile = createAsyncThunk("deleteFile", async ({ id }) => {
  const request = await api(`api/admin/file/${id}`, {}, "delete");
  return request;
});

const drawingFileSlice = createSlice({
  name: "drawingFile",
  initialState: {
    loading: false,
    files: [],
    success: null,
    error: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllFiles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllFiles.fulfilled, (state, action) => {
        state.files = action?.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllFiles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addFile.fulfilled, (state, action) => {
        state.loading = false;
        state.files.push(action.payload);
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(addFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(deleteFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(deleteFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default drawingFileSlice.reducer;
