import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faAngleRight,
  faAngleLeft,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import { getDdOptionForUI } from "../../store/reducer/ddOption/ddOptionSlice";
import { getOpportunityById } from "../../store/reducer/opportunity/opportunitySlice";
import { getDrawingById } from "../../store/reducer/drawing/drawingSlice";
import { debounce } from "lodash";
import { listPurchaseOrders } from "../../store/reducer/drawing/drawingPurchaseOrderSlice";
import {  getProposalFinalStatus } from "../../store/reducer/drawing/drawingFileSilce";
import Notes from "../Notes/Notes";

function PurchaseOrderHistory() {
  const { id, drawing_id } = useParams();

  const location = useLocation();
  const dispatch = useDispatch();
  const {
    loading,
    purchaseOrders = [],
    totalCount,
  } = useSelector((state) => state.drawingPurchaseOrder);

  const [opportunityData, setOpportunityData] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isProposalFinalised, setIsProposalFinalised] = useState(false);

  useEffect(() => {
    dispatch(getProposalFinalStatus(drawing_id)).then(result => {
      if(result?.payload?.isProposalFinalised) {
        setIsProposalFinalised(true);
      }
    })
    dispatch(getOpportunityById(id)).then((result) => {
      if (result.payload) {
        setOpportunityData(result.payload);
      }
    });

    dispatch(getDdOptionForUI());
    dispatch(listPurchaseOrders({ search, page, limit, drawing_id }));
  }, []);

  const handleSearch = (search = "") => {
    dispatch(listPurchaseOrders({ search, page, limit, drawing_id }));
  };
  const debouncedSearch = useCallback(debounce(handleSearch, 800), [
    purchaseOrders,
  ]);

  const handlePageClick = ({ selected }) => {
    const page = selected + 1;
    setPage(page);
    dispatch(listPurchaseOrders({ search, page, limit, drawing_id }));
  };

  return (
    <>
      <div className="grid grid-cols-3 items-center mb-[30px]">
        <div className="flex gap-[16px] items-center">
          <Link to={`/opportunity/${id}`}>
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Purchase Orders
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              PO History
            </span>
          </div>
        </div>
        <div className="font-Inter font-[500] leading-[30px] text-[18px] text-center">
          {opportunityData.id}-{opportunityData.opportunity_name}
        </div>
        <Notes type="drawing-purchaseorder-history" drawing_id={drawing_id} />
      </div>
      <div className="flex gap-[30px] bg-[#61b35436] p-2 rounded-lg mb-10 overflow-auto text-nowrap justify-between">
        <Link to={`/opportunity/${id}/drawing/${drawing_id}`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Drawing Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/site`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/site`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Site Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/take-off`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/take-off`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Take Off
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/bom`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/bom`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            BOM
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/install`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/install`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Install
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/additional-options`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/additional-options`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Additional Options
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/discount`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/discount`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Discount
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/pandl`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/pandl`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            P&L
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/proposal`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/proposal`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Proposal
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/files`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/files`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Files
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Create PO
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            PO History
          </div>
        </Link>
      </div>
      {isProposalFinalised ? (
        <div className="flex flex-col mt-[20px] gap-[40px]">
          <div className="">
            <div className="md:p-5 bg-[#F8F8F8] mt-[20px] rounded-l-[12px]">
              <div className="flex flex-col">
                <div className="md:flex justify-between items-center p-[12px]">
                  <div className="relative">
                    <input
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pl-[60px] rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="search"
                      name="search"
                      onChange={(e) => {
                        debouncedSearch(e.target.value);
                      }}
                    />
                    <div className="pointer-events-none absolute inset-y-0 flex items-center px-5 text-gray-700">
                      <img
                        src={process.env.PUBLIC_URL + "/icons/search.png"}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="sm:rounded-lg mt-5 overflow-scroll">
                  <table className="w-full">
                    <thead className="bg-white">
                      <tr className="rounded-[12px]">
                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                        >
                          Id
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                        >
                          Area
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                        >
                          Category
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                        >
                          Supplier
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                        >
                          View PO
                        </th>
                      </tr>
                    </thead>
                    {!loading ? (
                      <tbody>
                        {purchaseOrders.map((purchaseOrder) => (
                          <tr key={purchaseOrder.id}>
                            <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                              {purchaseOrder.id}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                              {purchaseOrder.area_name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                              {purchaseOrder.category}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                              {purchaseOrder.company}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                              {new Date(
                                purchaseOrder.createdAt
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-[14px] font-medium">
                              {purchaseOrder.po_file ? (
                                <a
                                  href={purchaseOrder.po_file}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View
                                </a>
                              ) : (
                                "NA"
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faSpinner}
                          size="2xl"
                          style={{ color: "#55A14A" }}
                          spin
                        />
                      </div>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center mt-[20px] gap-[40px] md:p-5 bg-[#F8F8F8] rounded-l-[12px]">
            <div className="hidden md:block font-Inter font-[300] text-nowrap">
              <span>
                Showing <b>{page}</b> of <b>{Math.ceil(totalCount / limit)}</b>{" "}
                pages
              </span>
            </div>
            <div className="w-full flex justify-end">
              <ReactPaginate
                className="react_pagination_ul"
                breakLabel={
                  <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                    <FontAwesomeIcon icon={faEllipsis} size="xl" />
                  </span>
                }
                nextLabel={
                  <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md">
                    <FontAwesomeIcon icon={faAngleRight} size="xl" />
                  </span>
                }
                onPageChange={handlePageClick}
                pageCount={Math.ceil(totalCount / limit)}
                previousLabel={
                  <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                    <FontAwesomeIcon icon={faAngleLeft} size="xl" />
                  </span>
                }
                activeClassName="bg-black text-white"
                pageClassName="block text-black  hover:bg-black hover:text-white rounded-md  mr-4"
                pageLinkClassName="w-10 h-10 flex justify-center items-center"
                containerClassName="flex items-center justify-center "
              />
            </div>
          </div>
        </div>
      ): (
        <div className="text-center">
          <span className="font-Inter font-[500] leading-[30px] text-[20px]">
              Please finalise proposal before viewing PO history.
            </span>
        </div>
      )}
    </>
  );
}

export default PurchaseOrderHistory;
